<template>
    <div>
        <v-container grid-list-xl fluid>
            <v-layout wrap>
                <v-flex xs12>
                    <h1>Pagamento</h1>
                    <v-divider class="mb-4" />
                </v-flex>

                <v-flex xs6>
                    <DateInterval @onChange="changeDate" today />
                </v-flex>
                <v-flex xs6 class="d-flex justify-center">
                    <v-switch v-model="onlyFinished" label="Somente finalizados" dense />
                </v-flex>

                <v-flex xs12>
                    <v-progress-linear progress color="primary" :value="timerProgress" />
                </v-flex>
                <v-flex xs12>
                    <v-card color="primary" dark>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5">
                                    R$ {{ total }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Valor total</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>alarm_add</v-icon>
                            </v-list-item-icon>
                            <v-list-item-subtitle><strong>Número de pagamentos: </strong>{{ listWithFilter.length }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item dense v-if="totalStripe > 0">
                            <v-list-item-subtitle><strong>Stripe: </strong>R$ {{ totalStripe }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item dense v-if="totalMercadoPago > 0">
                            <v-list-item-subtitle><strong>Mercado Pago(PIX): </strong>R$ {{ totalMercadoPago }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item dense v-if="totalMercadoPagoEbike > 0">
                            <v-list-item-subtitle><strong>EBike: </strong>R$ {{ totalMercadoPagoEbike }}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item dense v-if="totalOthers > 0">
                            <v-list-item-subtitle><strong>Outros: </strong>R$ {{ totalOthers }}</v-list-item-subtitle>
                        </v-list-item>
                    </v-card>
                </v-flex>
                <v-flex xs12 v-if="listWithFilter.length > 0">
                    <v-data-table :headers="headers" :items="listWithFilter" :items-per-page="-1" class="elevation-2" hide-default-footer :loading="!items">
                        <v-progress-linear v-slot:progress color="blue" indeterminate />
                        <template v-slot:[`item.charger`]="{ item }">
                            <strong>{{ item.charger }}</strong> ({{ item.plug }})
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                            <span>{{ item.date }}</span>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <Status :item="item" />
                        </template>
                        <template v-slot:[`item.value`]="{ item }">R$ {{ item.value }}</template>
                        <template v-slot:[`item.card`]="{ item }">
                            <Card :item="item" @click="openpayment(item.id)" />
                        </template>
                    </v-data-table>
                </v-flex>
            </v-layout>
        </v-container>

        <PaymentAction :id="idPayment" @close="idPayment = undefined" />
    </div>
</template>

<script>
import dateTime from "@/helpers/DateTime";
import DateInterval from "@/components/core/DateInterval";
import PaymentAction from "@/components/shared/PaymentAction";

import Card from "./Card";
import Status from "./Status";

export default {
    components: { DateInterval, Card, Status, PaymentAction },

    data() {
        return {
            dateTime,
            dates: [],
            processing: false,
            items: [],
            idPayment: undefined,
            timer: null,
            timerProgress: 100,
            onlyFinished: false,
        };
    },

    mounted() {
        this.timer = setInterval(this.timerTick, 500);
    },

    methods: {
        openpayment(id) {
            this.idPayment = id;
        },
        timerTick() {
            if (this.timerProgress > 0) {
                this.timerProgress--;
            } else {
                this.update();
                this.timerProgress = 100;
            }
        },
        changeDate(event) {
            this.dates = event;
            this.update(false);
        },
        update(continuous = true) {
            if (!this.processing) {
                this.processing = true;

                let query = this.dates.length == 2 ? `?sort[date]=ASC&dateFrom=${this.dates[0]}&dateTo=${this.dates[1]}` : `?dateFrom=${this.dates[0]}&dateTo=${this.dates[0]}`;

                this.$http
                    .get(`api/v2/payment${query}`, { continuous })
                    .then((result) => {
                        this.items = result;
                        this.processing = false;
                    })
                    .catch(() => {
                        this.processing = false;
                    });
            }
        },
    },

    computed: {
        listWithFilter() {
            if (this.onlyFinished) {
                return this.items.filter((item) => {
                    return item.status == "FINISH";
                });
            } else {
                return this.items;
            }
        },
        headers() {
            let headers = [
                { text: "Status", value: "status", align: "center" },
                { text: "Data", value: "date", align: "center" },
                { text: "Carregador", value: "charger", align: "center" },
                { text: "Valor", value: "value", align: "center" },
                { text: "Cartão", value: "card", align: "center" },
                // { text: "Usuário", value: "user", align: "center" },
            ];

            return headers;
        },
        total() {
            return Number.parseFloat(
                this.listWithFilter.reduce((sum, i) => sum + Number.parseFloat(i.value), 0)
            ).toFixed(2);
        },
        totalStripe() {
            return Number.parseFloat(
                this.listWithFilter.filter(i => i.operator == 'stripe').reduce((sum, i) => sum + Number.parseFloat(i.value), 0)
            ).toFixed(2);
        },
        totalMercadoPago() {
            return Number.parseFloat(
                this.listWithFilter.filter(i => i.operator == 'mp').reduce((sum, i) => sum + Number.parseFloat(i.value), 0)
            ).toFixed(2);
        },
        totalMercadoPagoEbike() {
            return Number.parseFloat(
                this.listWithFilter.filter(i => i.operator == 'MERCADO_PAGO_EBIKE').reduce((sum, i) => sum + Number.parseFloat(i.value), 0)
            ).toFixed(2);
        },
        totalOthers() {
            return Number.parseFloat(
                this.listWithFilter.filter(i => !['stripe', 'mp', 'MERCADO_PAGO_EBIKE'].includes(i.operator)).reduce((sum, i) => sum + Number.parseFloat(i.value), 0)
            ).toFixed(2);
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>