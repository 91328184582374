<template>
    <div>
        <div v-if="!item.carChargeData && !item.chargerHistory">
            <div class="pa-2 rounded-lg critical white--text" block v-if="item.status != 'CANCELED'">
                Pagamento não associado a carregador
            </div>
            <div class="pa-2 rounded-lg white black--text elevation-12" block v-else>
                🤖 Pagamento não associado a carregador, foi cancelado.
            </div>
        </div>

        <div class="pa-2 rounded-lg critical white--text" block v-if="item.status == 'CANCELED' && item.value > 0">
            Valor deveria ser zero.
        </div>

        <div class="pa-2 rounded-lg critical white--text" block v-if="!item.config">
            Configuração não localizada
        </div>
        <div :class="`pa-2 rounded-lg ${carChargeData.color} ${carChargeData.secondColor}--text`" block v-if="carChargeData && carChargeData.status != null">
            <v-row>
                <v-col cols="6">
                    <v-icon left :color="carChargeData.secondColor">{{ carChargeData.icon }}</v-icon>
                    {{ carChargeData.status }}
                </v-col>
                <v-col cols="6">
                    <strong class="ml-2">
                        {{ carChargeData.consumption ? Number.parseFloat(carChargeData.consumption).toFixed(2) : 0 }} kWh
                    </strong>
                    <strong class="ml-2">
                        R$ {{ getPrice(carChargeData.consumption) }}
                    </strong>
                </v-col>
            </v-row>
        </div>

        <div :class="`pa-2 rounded-lg ${chargerHistory.color} ${chargerHistory.secondColor}--text`" v-if="chargerHistory && chargerHistory.status != null">
            <v-row>
                <v-col cols="6">
                    <v-icon left :color="chargerHistory.secondColor">{{ chargerHistory.icon }}</v-icon>
                    {{ chargerHistory.status }}
                </v-col>
                <v-col cols="6">
                    <strong class="ml-2">
                        {{ chargerHistory.consumption ? Number.parseFloat(chargerHistory.consumption).toFixed(2) : 0 }} kWh
                    </strong>
                    <strong class="ml-2">
                        R$ {{ getPrice(chargerHistory.consumption) }}
                    </strong>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import statusCharger from "@/helpers/StatusCharger";

export default {
    props: {
        item: {
            type: Object,
        },
    },

    data() {
        return {
            statusCharger,
            carChargeData: { status: null, color: null, secondColor: null, icon: null, consumption: null },
            chargerHistory: { status: null, color: null, secondColor: null, icon: null, consumption: null },
            status: {},
        };
    },

    mounted() {
        if (this.item.carChargeData) {
            if (this.item.carChargeData.charger.data.online == 1) {
                this.carChargeData = this.statusCharger.getComponentStyle(this.item.carChargeData.status);
            } else {
                this.carChargeData = { status: "Offline", color: "grey", secondColor: "white", icon: "cloud_off" };
            }
            this.carChargeData.consumption = this.item.carChargeData.consumption;
        }

        if (this.item.chargerHistory) {
            if (this.item.chargerHistory.status == "PROCESSED") {
                this.chargerHistory.status = "Processado";
                this.chargerHistory.color = "success";
                this.chargerHistory.icon = "done_outline";
                this.chargerHistory.secondColor = "white";
            } else if (this.item.chargerHistory.status == "NEED_PROCESS" && this.item.chargerHistory.counter < 2) {
                this.chargerHistory.status = "Aguardando processamento";
                if (this.item.chargerHistory.counter == null) {
                    this.chargerHistory.color = "success";
                    this.chargerHistory.icon = "pending";
                    this.chargerHistory.secondColor = "white";
                } else {
                    this.chargerHistory.color = "warning";
                    this.chargerHistory.icon = "warning";
                    this.chargerHistory.secondColor = "white";
                }
            } else if (this.item.chargerHistory.status == "NEED_PROCESS") {
                this.chargerHistory.status = "Necessária ação do operador";
                this.chargerHistory.color = "critical";
                this.chargerHistory.icon = "error_outline";
                this.chargerHistory.secondColor = "white";
            } else if (this.item.chargerHistory.status == "PROCESSED_MANUAL") {
                this.chargerHistory.status = "Processado manualmente.";
                this.chargerHistory.color = "white";
                this.chargerHistory.icon = "warning";
                this.chargerHistory.secondColor = "black";
            } else if (this.item.chargerHistory.status == "PROCESSED_MANUAL_ERROR") {
                this.chargerHistory.status = "Necessário validação com a plataforma oficial do operador";
                this.chargerHistory.color = "error";
                this.chargerHistory.icon = "warning";
                this.chargerHistory.secondColor = "white";
            }
            this.chargerHistory.consumption = this.item.chargerHistory.consumption;
        }
    },

    methods: {
        getPrice(consumption) {
            if (this.item.config && this.item.config.pricePolicy) {
                if (this.item.config.pricePolicy == "PAYMENT_PER_CONSUMPTION") {
                    return Number.parseFloat(this.item.config.priceKwh * consumption).toFixed(2);
                }
                return Number.parseFloat(this.item.config.price * consumption).toFixed(2);
            }
            return 0;
        },
    },
};
</script>
